<template>
  <div>
    <v-app>
      <v-container>
        <v-row>
          <v-col lg="12" md="12" sm="12" xs="12">
            <v-tabs grow v-model="tab">
              <v-tab href="#organizasyondetaylari">
                Organizasyon detayları
              </v-tab>
              <v-tab href="#organizasyonyetkilileri">
                Organizasyon Yetkilileri
              </v-tab>
            </v-tabs>
            <v-tabs-items :value="tab">
              <v-tab-item value="organizasyondetaylari">
                <v-form v-model="isFormValid">
                  <v-row>
                    <v-col md="6">
                      <div class="form-group">
                        <v-text-field
                          required
                          v-model="selected.organizationName"
                          label="Organizasyon Adı (*)"
                          filled
                          dense
                          :rules="rules.required"
                        ></v-text-field>
                      </div>
                      <div class="form-group">
                        <v-autocomplete
                          auto-select-first
                          clearable
                          :items="titleitems"
                          label=" Organizasyon Tipi Seçiniz (*)"
                          v-model="selected.organizationType"
                          filled
                          dense
                          :rules="rules.required"
                        ></v-autocomplete>
                      </div>
                      <div class="form-group">
                        <v-autocomplete
                          auto-select-first
                          clearable
                          label="Ülke Seçiniz (*)"
                          v-model="selected.country"
                          filled
                          dense
                          :items="countryitems"
                          item-text="countryName"
                          item-value="countryId"
                          @change="getCityByCountryId()"
                          :rules="rules.required"
                        ></v-autocomplete>
                      </div>
                      <div class="form-group">
                        <v-autocomplete
                          clearable
                          auto-select-first
                          :rules="rules.required"
                          label="İlçe Seçiniz (*)"
                          v-model="selected.district"
                          filled
                          dense
                          :items="districtitems"
                          item-text="townName"
                          item-value="townId"
                          no-data-text="Lütfen Önce İl Seçiniz"
                        ></v-autocomplete>
                      </div>

                      <div class="form-group">
                        <v-text-field
                          required
                          v-model="selected.webAddress"
                          label="Web adresi"
                          filled
                          dense
                        ></v-text-field>
                      </div>
                    </v-col>

                    <v-col md="6">
                      <div class="form-group">
                        <v-text-field
                          required
                          v-model="selected.landPhone"
                          label="Sabit Telefon (*)"
                          filled
                          dense
                          maxlength="10"
                          :rules="rules.phone"
                        ></v-text-field>
                      </div>

                      <div class="form-group">
                        <v-text-field
                          required
                          v-model="selected.email"
                          label="Mail"
                          filled
                          dense
                          :rules="rules.email"
                        ></v-text-field>
                      </div>
                      <div class="form-group">
                        <v-autocomplete
                          auto-select-first
                          clearable
                          label="İl Seçiniz (*)"
                          v-model="selected.city"
                          filled
                          dense
                          :rules="rules.required"
                          :items="cityitems"
                          item-text="cityName"
                          item-value="cityId"
                          @change="getDistrictItems(selected.city)"
                          no-data-text="Lütfen Önce Ülke Seçiniz"
                        ></v-autocomplete>
                      </div>

                      <div class="form-group">
                        <v-text-field
                          required
                          v-model="selected.postCode"
                          label="Posta kodu"
                          filled
                          dense
                          :rules="rules.postcode"
                          maxlength="5"
                        ></v-text-field>
                      </div>
                      <div class="form-group">
                        <v-textarea
                          required
                          v-model="selected.address"
                          label="Açık Adres"
                          filled
                          dense
                        ></v-textarea>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col xl="2" lg="3" md="4 ml-auto">
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="send"
                        :disabled="!isFormValid"
                      >
                        Güncelle
                      </button>
                    </v-col>
                  </v-row>
                </v-form>
              </v-tab-item>
              <v-tab-item value="organizasyonyetkilileri">
                <v-row>
                  <v-col md="4">
                    <v-text-field
                      v-model="search"
                      append-icon="search"
                      label="Yetkili Ara"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-data-table
                      :headers="headers"
                      :items="organizationResearchers"
                      :search="search"
                      :footer-props="{
                        'items-per-page-options': [20, 30, 40, -1],
                      }"
                      :items-per-page="20"
                      :loading="loadings.table"
                      loading-text="Yükleniyor..."
                    >
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
    </v-app>
  </div>
</template>

<style lang="scss">
//@import "@/assets/sass/pages/wizard/wizard-3.scss";
</style>

<script>
//import KTCodePreview from "@/view/content/CodePreview.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { REMOVE_SELECTEDPAGEITEMNAME } from "@/core/services/store/selectedpageitem.module";
//import KTUtil from "@/assets/js/components/util";
//import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
//import { REGISTER } from "@/core/services/store/auth.module";
import ApiService from "@/core/services/api.service";
export default {
  name: "organizationsedit",
  data() {
    return {
      loadings: {
        table: false,
      },
      isFormValid: true,
      rules: {
        required: [(v) => !!v || "Bu Alan Doldurulması Zorunludur."],
        counter: [
          (v) =>
            v.length >= 10 || "Telefon Numaranızı 10 Haneli Olarak Giriniz",
        ],
        phone: [
          (v) => {
            var regex = /[0-9]{10}/;
            return regex.test(v) || "Geçersiz Telefon Numarası";
          },
        ],
        postcode: [
          (v) => {
            var regex = /[0-9]{5}/;
            return regex.test(v) || v.length == 0 || "Geçersiz Posta Kodu";
          },
        ],
        email: [
          (v) => {
            const pattern = /^([^<>()[\]\\,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
            return (
              pattern.test(v) ||
              v.length == 0 ||
              "Lütfen Geçerli E-posta Adresi Giriniz."
            );
          },
        ],
      },
      search: "",
      organizationResearchers: [],
      objeler: {
        id: "",
      },
      currentid: null,
      gecici: "",
      selected: {
        organizationName: "",
        organizationType: "",
        landPhone: "",
        email: "",
        webAddress: "",
        country: null,
        city: null,
        district: null,
        postCode: "",
        address: "",
      },
      selectedCountryId: "",
      selectedCityId: "",
      titleitems: [
        {
          text: "Sözleşmeli Araştırma Kuruluşu",
          value: "Sözleşmeli Araştırma Kuruluşu",
        },
        { text: "Destekleyici", value: "Destekleyici" },
        { text: "SGK", value: "SGK" },
        { text: "TİTCK", value: "TİTCK" },
        { text: "Diğer", value: "Diğer" },
      ],
      countryitems: [],
      cityitems: [],
      districtitems: [],
      headers: [
        {
          text: "Adı",
          align: "left",
          value: "name",
        },
        { text: "Soyadı", value: "surname" },
        { text: "Kullanıcı Adı", value: "username" },
        { text: "Ünvanı", value: "title" },
        { text: "Merkez/Organizasyon", value: "center.centerName" },
        { text: "Bölüm", value: "specialtyDepartment" },
        { text: "E-posta", value: "email" },
        { text: "Telefon", value: "phoneNumber" },
        { text: "Ülke", value: "country" },
        { text: "İl", value: "city" },
      ],
    };
  },
  destroyed() {
    this.$store.dispatch(REMOVE_SELECTEDPAGEITEMNAME);
  },
  mounted() {
    let selectedPage = this.$store.state.selectedpageitem.selectedPageItemName;
    
    if (selectedPage === "") {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Organizasyon Güncelleme" },
      ]);
    } else {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: selectedPage, route: "/organizationlist" },
        { title: "Organizasyon Güncelleme" },
      ]);
    }

    var temp = localStorage.getItem("itemler");
    this.objeler = JSON.parse(temp);
    this.currentid = this.objeler.id;
    this.getCurrentOrganizationsinfo();
  },
  methods: {
    send() {
      
      ApiService.put("/organization", this.selected)
        .then(({ data }) => {
          console.log("yeni kayıt güncellendi", data);
          Swal.fire({
            icon: "success",
            title: "Güncelleme işlemi başarılı",
            showConfirmButton: false,
            timer: 1200,
          });
        })
        .catch((err) => {
          console.log("error", err);
          Swal.fire({
            title: "",
            text: err.response.data.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    getCountryItems() {
      ApiService.get("countries")
        .then(({ data }) => {
         
          this.countryitems = data;
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    getCity(selectedCountryId) {
      if (selectedCountryId == null) {
        this.districtitems = [];
      }

      ApiService.get("cities", selectedCountryId)
        .then(({ data }) => {
          this.cityitems = data;
          
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    getCityByCountryId() {
      this.selected.city = "";
      this.selected.district = "";
      this.districtitems = [];
     
      if (this.selected.country == null) {
        this.cityitems = [];
      }
      this.getCity(this.selected.country);
    },
    getDistrictItems(selectedCityId) {
      if (selectedCityId == null) {
        this.districtitems = [];
      }
      ApiService.get("towns", selectedCityId)
        .then(({ data }) => {
          this.districtitems = data;
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    getDistrictItemsByCityId(changeSelectedCity) {
      let changeSelectedCityId = this.cityitems.filter(
        (city) => city.cityName == changeSelectedCity
      );
      this.selected.district = "";

      
      ApiService.get("towns", changeSelectedCityId[0].cityId)
        .then(({ data }) => {
          this.districtitems = data;
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    getCurrentOrganizationsinfo() {
      ApiService.get("/organization", this.currentid)
        .then(({ data }) => {
          console.log("Organizasyon bilgileri geldi", data);
          this.selected = data.result;
          this.organizationResearchers = this.selected.users;
          this.selected.city = data.addResult[1].cityId;
          this.selected.country = data.addResult[0].countryId;
          this.selected.district = data.addResult[2].townId;
          console.log("selected ", this.selected);
          this.getCountryItems();
          this.getCity(this.selected.country);
          this.getDistrictItems(this.selected.city);
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
  },
  computed: {
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get() {
        console.log("query", this.$route.query.tab);
        return this.$route.query.tab;
      },
    },
  },
};
</script>
